'use client';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';

const bannerMessage = process.env.NEXT_PUBLIC_SITE_WIDE_BANNER_MESSAGE;

const MaintenanceBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const dismissedMessage = localStorage.getItem('dismissedBannerMessage');
    if (bannerMessage && dismissedMessage !== bannerMessage) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    if (bannerMessage) {
      localStorage.setItem('dismissedBannerMessage', bannerMessage);
    }
    setIsVisible(false);
  };

  if (!isVisible || !bannerMessage) return null;

  return (
    <Alert
      severity="info"
      variant="filled"
      sx={customStyles}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <Box>{bannerMessage}</Box>
    </Alert>
  );
};

export default MaintenanceBanner;

const customStyles = {
  position: 'fixed',
  bottom: 50,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1300,
  display: 'flex',
  alignItems: 'center',
  px: 4,
  py: 1.5,
  color: 'white',
  width: {
    xs: '80%',
    sm: '70%',
    md: '50%',
  },
};
